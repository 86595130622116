import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

import { Link } from 'gatsby';
import React from 'react';

const TeamMemberMobile = ({
  images,
  names,
  designations,
  currentTab,
  setCurrentTab,
  teamContentMobile,
}) => {
  const handleClickRightArrow = () => {
    const newTab = (currentTab + 1) % 3;
    setCurrentTab(newTab);
  };
  const handleClickLeftArrow = () => {
    const newTab = (currentTab + 3 - 1) % 3;
    setCurrentTab(newTab);
  };

  return (
    <div className='flex flex-col items-center text-center sm:hidden'>
      <div className='flex justify-between w-full'>
        <button className='' onClick={() => handleClickLeftArrow()}>
          <ChevronLeftIcon className='h-10 w-10 text-blue-500' />
        </button>
        <Link to={`team?founder=${names[currentTab].split(' ')[0]}`}>
          <div className='w-40 h-40 lg:w-60 lg:h-60 overflow-hidden shadow-md rounded-md mb-6 bg-contain'>
            <img
              className='object-cover min-w-full min-h-full'
              src={images[currentTab]}
              alt='Person'
            ></img>
          </div>
        </Link>
        <button className='' onClick={() => handleClickRightArrow()}>
          <ChevronRightIcon className='h-10 w-10 text-blue-500' />
        </button>
      </div>
      <Link to={`team?founder=${names[currentTab].split(' ')[0]}`}>
        <div className=''>{names[currentTab]}</div>
        <div className=''>{designations[currentTab]}</div>
      </Link>
      <div className='mt-10'>{teamContentMobile[currentTab]}</div>
    </div>
  );
};

export default TeamMemberMobile;
