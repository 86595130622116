import { Link } from 'gatsby';
import React from 'react';

const TeamMember = ({
  image,
  title,
  subTitle,
  imageOnLeft,
  children,
  extraClassNames,
}) => {
  const comp1 = (
    <div className='flex-col items-strech justify-center text-center'>
      <Link to={`team?founder=${title.split(' ')[0]}`}>
        <div className='w-32 h-32 lg:w-60 lg:h-60 overflow-hidden shadow-md rounded-md mb-6 bg-cover '>
          <img
            className='object-cover min-w-full min-h-full'
            src={image}
            alt='Person'
          ></img>
        </div>
        <h4 className='font-bold text-sm lg:text-xl hover:text-primary'>
          {title}
        </h4>
        <h5 className=' text-xs lg:text-lg hover:text-primary'>{subTitle}</h5>
      </Link>
    </div>
  );
  const comp2 = (
    <div
      className={`
        w-50
        p-4 sm:p-10 md:p-14 lg:p-20
        py-2
        md:pt-6
        lg:pt-20
        pt-2
        sm:pt-4
        ${imageOnLeft ? 'text-left' : 'text-right'}
      `}
    >
      <div className=' text-sm text-gray-400 lg:text-xl'>{children}</div>
    </div>
  );
  return (
    <div className={`sm:flex justify-around mb-5 hidden ${extraClassNames}`}>
      {imageOnLeft ? comp1 : comp2}
      {imageOnLeft ? comp2 : comp1}
    </div>
  );
};

export default TeamMember;
