import React from 'react';
import SolutionItem from './solutionItem';

const Solutions = () => {
  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 gap-12 mb-10'>
      <SolutionItem
        title='SmartPharm'
        tab1='SmartADR'
        tab2='SmartDDI'
        tab3='SmartDose'
      />
      <SolutionItem
        title='SmartDoc'
        tab1='SmartTx'
        tab2='SmartLab'
        tab3='SmartCalc'
      />
    </div>
  );
};

export default Solutions;
