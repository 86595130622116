import { ChevronRightIcon } from '@heroicons/react/solid';
import { Link } from 'gatsby';
import React from 'react';
import SmartDocAvatar from '../../images/solutions/SmartDoc.png';
import SmartPharmAvatar from '../../images/solutions/SmartPharm.png';

const SolutionItem = ({ title, tab1, tab2, tab3 }) => {
  return (
    <div className=' flex justify-between md:pl-12 pl-0 sm:gap-6 md:gap-0 lg:gap-6 pb-6 content-between  '>
      <div className='h-36 md:h-36 lg:h-52 overflow-hidden'>
        {title === 'SmartPharm' ? (
          <img
            className='w-full h-full'
            src={SmartPharmAvatar}
            alt='SmartPharm'
          />
        ) : (
          <img className='w-full h-full' src={SmartDocAvatar} alt='SmartDoc' />
        )}
      </div>
      <div className='flex-col  align-middle items-start w-full pl-6 basis-2/3'>
        <h1 className='font-bold text-lg md:text-xl lg:text-3xl pb-4 '>
          {title}
        </h1>
        <h1 className='font-light text-solutions_airy_base sm:text-solutions_airy_base2 md:text-solutions_airy_lg lg:text-airy_xl  pb-6'>
          {tab1} <br />
          {tab2} <br />
          {tab3} <br />
        </h1>
        <Link to='solutions' className='justify-center'>
          <div className='flex py-2 w-40 border-2 border-primary bg-primary rounded-full text-base  md:text-md font-medium sm:font-semibold  text-white text-center items-center gap-2 justify-center hover:bg-primaryDark hover:border-primaryDark'>
            <h5>Read More</h5>
            <ChevronRightIcon className=' h-6 w-6' />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default SolutionItem;
