import React, { useRef, useState } from 'react';

import CommonLayout from '../components/home/commonLayout';
import HeroSection from '../components/home/heroSection';
import Layout from '../components/layout';
import PharmCorpHeading from '../components/typography/heading';
import SEOTag from '../components/seo/seo';
import Solutions from '../components/home/solutions';
import TeamMember from '../components/home/teamMember';
import TeamMemberMobile from '../components/home/teamMemberMobile';
import member1 from '../images/member1.jpeg';
import member2 from '../images/member2.png';
import member3 from '../images/member3.jpeg';

const images = [member1, member2, member3];
const names = ['Jorge Barquin, PharmD', 'Jagger Esch', 'Jose Barquin Jr., MD'];
const designations = ['Founder, President & CEO', 'Co-Founder', 'Co-Founder'];
const teamContent = [
  <div>
    Society’s standard for technology is remarkably high in virtually all US
    industries. I started to question why this standard does not apply to
    software we use to treat patients and was surprised that nobody could
    provide an answer. Then, it occurred to me that I was not seeking an answer
    to a question. I had found a problem and was looking for a solution. That
    solution became PharmCorps.
    <br />
    <br />
  </div>,
  <div>
    After a decade of success as an entrepreneur, I’ve learned the primary
    driver for success in business is a genuine and noble cause. There is no
    doubting the value of the product, but it was Jorge’s unwavering commitment
    to helping others that made PharmCorps more than just another business to
    me. It was an opportunity to make a difference by helping someone achieve an
    admirable goal.
    <br />
    <br />
  </div>,
  <div>
    As a physician working full time in the hospital ER setting, I am inundated
    with information at a rate that can many times seem overwhelming. PharmCorps
    was my opportunity to streamline the remarkable technology already in our
    hands to make my practice of medicine more efficient, less prone to error,
    and higher in quality for my patients. Working alongside my little brother
    was the icing on the cake!
    <br />
    <br />
  </div>,
];

// markup
const HomePage = () => {
  const ref = useRef(null);
  const missionRef = useRef(null);
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Layout missionRef={missionRef} container={false}>
      <SEOTag title='Home | Pharmcorps' />
      <HeroSection scrollRef={ref} />

      {/* <CommonLayout extra_class_names='container mx-auto'>
        <PharmCorpHeading
          lightText='Our'
          boldText='Partners'
          extraClassNames='mb-10 mt-4 sm:mt-8 md:mt-12 lg:mt-16'
          passRef={ref}
        />
        <Partners />
      </CommonLayout> */}

      {/* <div className='w-28 h-0.5 md:h-1 md:w-40 lg:w-60 bg-primary mx-auto my-20'></div> */}

      <CommonLayout extra_class_names='container mx-auto'>
        <PharmCorpHeading
          lightText='Our'
          boldText='Solutions'
          extraClassNames='mb-10 mt-4 sm:mt-8 md:my-12 lg:my-16'
          passRef={ref}
        />
        <Solutions />
      </CommonLayout>

      <div
        className='flex-col items-center bg-gradient-to-b from-primary to-primaryDark text-white overflow-hidden'
        ref={missionRef}
        id='mission'
      >
        <div className='px-10 justify-between sm:px-8 md:px-24 lg:px-32 container mx-auto'>
          <PharmCorpHeading
            lightText='Our'
            boldText='Mission'
            extraClassNames='mb-4 sm:mb-10 mt-12  md:mt-12 lg:mt-16'
          />
          <div className='grid grid-cols-1 sm:grid-cols-2 sm:gap-10 md:gap-24 pb-12'>
            <div className='font-normal text-airy_base md:text-airy_xl tracking-widest lg:text-airy_xl pb-6 '>
              PharmCorps aims to maximize the clinician’s ability to provide
              evidence-based care in today’s clinical setting, where time is a
              scarce commodity.
              <br />
              <br /> Using an intuitive and user-friendly interface, our
              products transform the landscape of the patient chart into a
              bridge where patient-specific, clinical evidence meets the mind of
              the clinician.
            </div>
            <div className='h-80 sm:h-96 '>
              <div className=' relative mb-6 h-72 sm:h-full'>
                <div className='absolute -translate-x-1/4 sm:translate-x-0 lg:-translate-y-24 left-1/4 sm:left-0 sm:top-10 z-10 bg-home_mission_section bg-cover h-60 w-60 sm:h-72 sm:w-72 md:h-96 md:w-80 lg:w-96' />
                <div className='absolute -translate-x-1/4 sm:translate-x-0 lg:-translate-y-24 left-1/3 top-10 w-72 sm:top-0 sm:left-20 z-0 sm:w-60 md:w-80 lg:w-96 blank h-full sm:h-96 md:h-full bg-white' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommonLayout extra_class_names='container mx-auto'>
        <PharmCorpHeading
          lightText='Meet our'
          boldText='Team'
          extraClassNames='mb-10  mt-12  md:mt-12 lg:mt-16'
        />
        {images.map((image, i) => (
          <TeamMember
            key={i}
            image={image}
            title={names[i]}
            subTitle={designations[i]}
            imageOnLeft={i % 2 === 0}
            extraClassNames=''
          >
            {teamContent[i]}
          </TeamMember>
        ))}
        <TeamMemberMobile
          images={images}
          names={names}
          designations={designations}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          teamContentMobile={teamContent}
        />
      </CommonLayout>
    </Layout>
  );
};

export default HomePage;
