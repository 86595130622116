import { ChevronDownIcon } from '@heroicons/react/solid';
import React from 'react';

const HeroSection = ({ scrollRef }) => {
  const handleClick = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className=' overflow-hidden  relative'>
      <section class='hero-section bg-hero_section drop-shadow-lg bg-cover bg-center'>
        <div class='grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12'>
          <div class='mr-auto place-self-center lg:col-span-7 '>
            <div
              className='relative z-10 flex flex-col flex-wrap '
              style={{ height: '70vh', justifyContent: 'center' }}
            >
              <div className='p-2 z-10 relative flex flex-col '>
                <h4 className='text-black text-2xl lg:text-5xl sm:text-3xl md:text-4xl font-bold mb-12'>
                  Achieving universal evidence-based care.
                </h4>
                <button
                  className='py-2 w-40 relative z-20 border-2 border-primary bg-primary rounded-3xl items-center justify-center text-lg  font-medium text-white hover:bg-primaryDark hover:border-primaryDark justify-center'
                  onClick={handleClick}
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
